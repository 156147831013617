.content {
  padding: 60px 20px;
  max-width: 1200px;
  margin: 0 auto;
  color: #fff;

  @media (max-width: 576px) {
    padding: 30px 0;
  }
}

.sectionTitle {
  font-family: CrimsonText-Italic;
  font-size: 32px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 40px;
  font-style: italic;
  color: #fff;
}

.blogGrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  margin-bottom: 40px;

  @media (max-width: 900px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 576px) {
    gap: 15px;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 20px;
  }
}

.blogCard {
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.3s ease;
  background: #272429;
}

.imageContainer {
  position: relative;
  width: 100%;
  aspect-ratio: 16/9;
  overflow: hidden;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.blogInfo {
  padding: 15px;
}

.blogTitle {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
  line-height: 1.4;
  color: #fff;
}

.blogDate {
  font-size: 12px;
  color: #888;
}

.moreButtonContainer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.moreButton {
  background-color: transparent;
  border: 1px solid #333;
  color: #fff;
  padding: 8px 40px;
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  .arrowDown {
    font-size: 10px;
  }
}

